import add_property_widget from "./widgets/add-property-widget.vue";
import my_properties_widget from "./widgets/my-properties-widget.vue";
import user_catalogue_widget from "./widgets/user-catalogue-widget.vue";
import favorite_properties_widget from "./widgets/favorite-properties-widget.vue";
import shared_with_me_properties_widget from "./widgets/account/shared-with-me-properties-widget.vue";
import messages_widget from "./widgets/messages-widget.vue";
import map_search_widget from "./widgets/map-search-widget.vue";
import auth_page from "./pages/auth-page.vue";
import uploader_contact_info_widget from "./widgets/uploader-contact-info-widget.vue";
import contact_us_widget from "./widgets/contact-us-widget.vue";
// Agency
import agencies_list from "./widgets/agency/agencies-list.vue";
import members_list from "./widgets/agency/members-list.vue";
// Account
import occupied_properties_widget from "./widgets/account/occupied-properties-widget.vue";
import deals_list from "./widgets/account/deals-list.vue";
import my_balance from "./widgets/account/my-balance.vue";
import dashboard_widget from "./widgets/account/dashboard-widget.vue";
import edit_profile from "./widgets/account/edit-profile.vue";

import full_loading from "./components/full-loading.vue";
import notification_component from "./components/notification-component.vue";
import favorite_component from "./components/favorite-component.vue";
import total_expense_component from "./components/total-expense-component.vue";
import property_component_horizontal from "./components/property-component-horizontal.vue";
import filter_tablets_component from "./components/filter-tablets-component.vue";
import single_property_component from "./components/single-property-component.vue";
import property_information_component from "./components/property-information-component.vue";
import property_features_component from "./components/property-features-component.vue";
import search_form_component from "./components/landing/search-form-component.vue";

import search_filters_modal from "./modal/search-filters-modal.vue";
import add_expense_modal from "./modal/add-expense-modal.vue";
import report_modal from "./modal/report-modal.vue";
import unpublish_modal from "./modal/unpublish-modal.vue";
import add_member_modal from "./modal/add-member-modal.vue";
import expense_details_modal from "./modal/expense-details-modal.vue";
import child_details_modal from "./modal/child-details-modal.vue";
import maintenance_request_modal from "./modal/maintenance-request-modal.vue";
import upload_transfer_modal from "./modal/upload-transfer-modal.vue";
import nafath_verification from "./modal/nafath-verification.vue";
import share_property_modal from "./modal/share-property-modal.vue";
import Vue from '../node_modules/vue/dist/vue';
// import Vue from 'vue'; 
import * as global from "../js/global" ;

$(document).ready(function(){
    if($("#common-vue-div").length){
        $("#common-vue-div").init( function(){
            const app = createVue();
            // app.config.warnHandler = function (msg, vm, trace) {
            //     console.log("warning handler");
            //     return null;
            // }
        })
    }
});

function createVue() {
    const app = new Vue({
        components: {
            full_loading:full_loading,
            notification_component:notification_component,
            favorite_component:favorite_component,
            add_property_widget: add_property_widget,
            my_properties_widget: my_properties_widget,
            user_catalogue_widget: user_catalogue_widget,
            occupied_properties_widget: occupied_properties_widget,
            favorite_properties_widget: favorite_properties_widget,
            shared_with_me_properties_widget: shared_with_me_properties_widget,
            messages_widget: messages_widget,
            map_search_widget:map_search_widget,
            total_expense_component:total_expense_component,
            auth_page:auth_page,
            property_component_horizontal:property_component_horizontal,
            uploader_contact_info_widget:uploader_contact_info_widget,
            contact_us_widget:contact_us_widget,
            search_filters_modal:search_filters_modal,
            add_expense_modal:add_expense_modal,
            report_modal:report_modal,
            unpublish_modal:unpublish_modal,
            add_member_modal:add_member_modal,
            expense_details_modal:expense_details_modal,
            child_details_modal:child_details_modal,
            maintenance_request_modal:maintenance_request_modal,
            upload_transfer_modal:upload_transfer_modal,
            filter_tablets_component:filter_tablets_component,
            single_property_component:single_property_component,
            property_information_component:property_information_component,
            property_features_component:property_features_component,
            search_form_component:search_form_component,
            // Agency
            agencies_list:agencies_list,
            members_list:members_list,
            // Account
            deals_list:deals_list,
            my_balance:my_balance,
            dashboard_widget:dashboard_widget,
            edit_profile:edit_profile,
            nafath_verification:nafath_verification,
            share_property_modal:share_property_modal,
        },
        el:'#common-vue-div',
        delimiters: ["_{", "}_"],
        mounted(){
            global.emitter.on("refresh-tooltips", args => {
                console.log("called refresh-tooltips");
                this.refresh_tooltips();
            });
            this.refresh_tooltips();
            setTimeout(()=>{
                qrcode_element = document.getElementById("qrcode");
                if(qrcode_element){
                    var qrcode = new QRCode(qrcode_element, {
                        width : 120,
                        height : 120
                    });
                
                    var elText = qrcode_element.getAttribute('data-qr-code');
                    qrcode.makeCode(elText);
                }

                $('#discover-slide').owlCarousel({
                    items: 3,
                    loop: false,
                    margin: 30,
                    nav: true,
                    dots: false,
                    autoplay: true,
                    smartSpeed: 1000,
                    autoplayHoverPause: true,
                    navText: [
                        "<i class='ri-arrow-left-line'></i>",
                        "<i class='ri-arrow-right-line'></i>",
                    ],
                    responsive: {
                        0: {
                            items: 2,
                        },
                        576: {
                            items: 3,
                        },
                        768: {
                            items: 4,
                        },
                        992: {
                            items: 5,
                        },
                        1200: {
                            items: 6,
                        },
                    },
                });

            }, 500);
        },
        methods: {
            refresh_tooltips(){
                setTimeout(()=>{
                    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
                    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
                        delete  tooltipTriggerEl.dataset.bsToggle
                        return new bootstrap.Tooltip(tooltipTriggerEl)
                    });
                }, 500);
            }
        }
    });
    return app;
}